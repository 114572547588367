html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* font-family: 'GTHaptik', sans-serif; */
  font-family: 'GTAlpina', serif;
  font-feature-settings: "ss01";
  font-weight: 400;
  line-height: 1;

  /* font-size: responsive 20px 36.25px; */
  /* text-rendering: optimizeLegibility; */
}

.image-wrap {
  width: 100%;
  padding-bottom: 66.66%;
  position: relative;
  /* background: #d8d8d8; */
}
.image-wrap img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
/* Loader */
.loader {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: left top;
  
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: red;
  opacity: 1;
  
  animation: sk-bounce 2.0s infinite ease-in-out;
}
.img-loaded + .loader {
  display: none;
}

/* loader anim */
@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.5) translate(-50%, -50%);
  } 50% { 
    transform: scale(1.0) translate(-50%, -50%);
  }
}


@keyframes fadeInImg {
 from {
  opacity: 0;
  /* transform: translateY(10px); */
 } to {
  opacity: 1;
  /* transform: translateY(0); */
}
}
.img-loading {
   opacity: 0;
   width: 100%;
   height: auto;
}
.img-loaded {
   animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
   position: relative;
   opacity: 0;
   animation-fill-mode: forwards;
   animation-duration: 0.7s;
   animation-delay: 0.1s;
}
.no-fade .img-loaded {
  opacity: 1 !important;
}

img {
  /* display: block;
  width: 100%; */
  /* TODO: swap out this component for something smoother: react-graceful-image */
  /* animation: none !important; */
  /* animation-duration: 600ms !important;
  animation-delay: 0 !important; */

  /* For image lazyload */
  transition: 0.4s opacity ease-out;
}
img.no-fade {
  animation: none !important;
}

p {
  text-indent: 2rem;
}

p a {
  text-decoration: underline;
  white-space: nowrap;
}
p a:hover,
span a:hover {
  color: #ff0000;
}

.random-phrase {
  transform: translate3d(0,0,0);
  white-space: pre-line;
  overflow-wrap: break-word;
  font-display: block;
}

/* 
.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}
@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
} */

.button-dice {
  position: absolute;
  top: -3rem;
  right: 1rem;
  transition: transform 0.2s ease-out;
}
.button-dice:hover {
  transform: rotate(-45deg);
  color: red;
}


/* Special overlay classes */
.mix-screen {
  mix-blend-mode: screen;
}
.mix-lighten {
  mix-blend-mode: lighten;
}
.mix-multiply {
  mix-blend-mode: multiply;
}
.filter-grayscale {
  filter: grayscale(100%);
}

.overlay {
  position: relative;
}
.overlay:before {
  /* display: none; */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mix-blend-mode: screen;
  opacity: 1;
  /* transition: 3s opacity ease-out;
  transition-delay: 1s;
  transform: translate3d(0,0,0); */
}
.overlay-red:before {
  background-color: #ff0000;
}
.overlay-green:before {
  background-color: #037533;
}
.overlay-yellow:before {
  background-color: #FBB03B;
}
.overlay-grey:before {
  background-color: #B3B3B3;
}
.overlay img {
  animation: none !important; 
}

.rotate-center {
	-webkit-animation: rotate-center 0.6s ease-in-out both;
	        animation: rotate-center 0.6s ease-in-out both;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.rotate-15 { transform: rotate(15deg); }
.rotate-45 { transform: rotate(45deg); }
.rotate-75 { transform: rotate(75deg); }
.rotate-90 { transform: rotate(90deg); }

.rotate-neg15 { transform: rotate(-15deg); }
.rotate-neg45 { transform: rotate(-45deg); }
.rotate-neg75 { transform: rotate(-75deg); }
.rotate-neg90 { transform: rotate(-90deg); }

.postcard {
  cursor: pointer;
  transform: scale(0.99);
  transition: transform ease-out;
  box-shadow: 0 0 10px 3px rgba(0,0,0,0.3);
  /* mask-image: -webkit-radial-gradient(circle, white, black); */
  transform: translate3d(0, 0, 0);
}
.postcard:nth-child(1){transform: rotate(-3deg);}
.postcard:nth-child(2){transform: rotate(4deg);}
.postcard:nth-child(3){transform: rotate(-2deg);}
.postcard:nth-child(4){transform: rotate(-6deg);}
.postcard:nth-child(5){transform: rotate(4deg);}
.postcard:nth-child(6){transform: rotate(-3deg);}

.postcard.active {
  z-index: 100;
  box-shadow: 0 0 12px 5px rgba(0,0,0,0.4);
  transform: scale(1) rotate(0deg) !important;
  transition: transform 0.3 ease-in-out;
}
.postcard:not(.active):hover:before,
.postcard:not(.active):focus:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: #FBB03B;
  mix-blend-mode: multiply;
}

.inner-border {
  /* -webkit-mask-image: -webkit-radial-gradient(white, black); */
  mask-image: -webkit-radial-gradient(circle, white, black);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.scale-90pc {
  transform: scale(0.9);
}
.inner-border:before {
  content: '';
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: 5;
  box-shadow: 0 0 0 3rem white inset;
  border-radius: 100%;
}

.transform-center {
  transform-origin: center;
}

/* Hide parallax elements before scrolling into view */
.Plx--above {
  opacity: 0;
}

/* Page navigation */
.page-nav {
  transform: translateX(-2rem);
  transition: transform 0.1s ease-out;
  padding-right: 1rem;
}
.page-nav.toggled {
  transform: translateX(0);
}
.page-nav a {
  position: relative;
  display: block;
  flex-grow: 1;
  padding-left: 0.2rem;
  padding-right: 1rem;
  background-color: white;
  /* #d8d8d8 */
  color: black;
  transition: background-color 0.5s ease-in-out;
  text-align: left;
  box-shadow: 0 2px 0 0 black inset;
  border-right: 2px solid black;
  z-index: 1;
}
.page-nav a:first-child {
  box-shadow: none !important;
}
.page-nav a:hover {
  color: red;
  cursor: pointer;
}
.page-nav a.is-active {
  margin-right: -0.75rem;
  background-color: red;
  border-radius: 0 0.4rem 0.4rem 0;
  border-right: none;
  pointer-events: none;
  box-shadow: none;
}
.page-nav a.is-active:hover {
  color: white;
}
.page-nav a.is-active + a {
  box-shadow: none;
}
.page-nav button {
  /* display: none; */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 3rem;
  background-color: transparent;
  z-index: 0;
  transition: background 0.3s ease-in-out;
  background-image: linear-gradient(90deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
  background-position: -1rem;
  background-repeat: no-repeat;
}
.page-nav button:hover {
  background-image: linear-gradient(90deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
  background-position: 0; 
}
.page-nav button:focus {
  outline: none;
}

.tokyo-metro img,
.tokyo-metro svg {
  /* width: 200% !important; */
  max-width: none;
  display: block;
  background-color: white;
}
.tokyo-metro .plx {
  width: 200%;
}

.transform-top-center {
  transform-origin: top center;
}

::selection {
  color: black;
  background: #fbb03b;
}

.shimokitazawa {
  text-shadow:
    1px 1px 1px #c58015,
    1px 2px 1px #c58015,
    1px 3px 1px #c58015,
    1px 4px 1px #c58015,
    1px 5px 1px #c58015,
    1px 6px 1px #c58015,
    0 6px 5px rgba(0,0,0,0.8);
  transform: translate3d(0,0,0);
}

.color-inherit {
  color: inherit;
}