@font-face {
  font-family: 'GTHaptik';
  src: local('GTHaptik'), url('../fonts/GT-Haptik-Bold.eot');
  src: local('GTHaptik'), url('../fonts/GT-Haptik-Bold.eot?#iefix') format('embedded-opentype'),
        local('GTHaptik'), url('../fonts/GT-Haptik-Bold.woff2') format('woff2'),
        local('GTHaptik'), url('../fonts/GT-Haptik-Bold.woff') format('woff'),
        local('GTHaptik'), url('../fonts/GT-Haptik-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'GTHaptik';
  src: local('GTHaptik'), url('../fonts/GT-Haptik-Bold-Oblique.eot');
  src: local('GTHaptik'), url('../fonts/GT-Haptik-Bold-Oblique.eot?#iefix') format('embedded-opentype'),
        local('GTHaptik'), url('../fonts/GT-Haptik-Bold-Oblique.woff2') format('woff2'),
        local('GTHaptik'), url('../fonts/GT-Haptik-Bold-Oblique.woff') format('woff'),
        local('GTHaptik'), url('../fonts/GT-Haptik-Bold-Oblique.ttf') format('truetype');
  font-weight: 700;
  font-style: oblique;
}

@font-face {
  font-family: 'GTHaptik';
  src: local('GTHaptik'), url('../fonts/GT-Haptik-Bold-Rotalic.eot');
  src: local('GTHaptik'), url('../fonts/GT-Haptik-Bold-Rotalic.eot?#iefix') format('embedded-opentype'),
        local('GTHaptik'), url('../fonts/GT-Haptik-Bold-Rotalic.woff2') format('woff2'),
        local('GTHaptik'), url('../fonts/GT-Haptik-Bold-Rotalic.woff') format('woff'),
        local('GTHaptik'), url('../fonts/GT-Haptik-Bold-Rotalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'GTAlpina';
  src: local('GTAlpina'), url('../fonts/GT-Alpina-Regular.eot');
  src: local('GTAlpina'), url('../fonts/GT-Alpina-Regular.eot?#iefix') format('embedded-opentype'),
        local('GTAlpina'), url('../fonts/GT-Alpina-Regular.woff2') format('woff2'),
        local('GTAlpina'), url('../fonts/GT-Alpina-Regular.woff') format('woff'),
        local('GTAlpina'), url('../fonts/GT-Alpina-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GTAlpina';
  src: local('GTAlpina'), url('../fonts/GT-Alpina-Bold.eot');
  src: local('GTAlpina'), url('../fonts/GT-Alpina-Bold.eot?#iefix') format('embedded-opentype'),
        local('GTAlpina'), url('../fonts/GT-Alpina-Bold.woff2') format('woff2'),
        local('GTAlpina'), url('../fonts/GT-Alpina-Bold.woff') format('woff'),
        local('GTAlpina'), url('../fonts/GT-Alpina-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'GTAlpina';
  src: local('GTAlpina'), url('../fonts/GT-Alpina-Regular-Italic.eot');
  src: local('GTHaptik'), url('../fonts/GT-Alpina-Regular-Italic.eot?#iefix') format('embedded-opentype'),
        local('GTHaptik'), url('../fonts/GT-Alpina-Regular-Italic.woff') format('woff'),
        local('GTHaptik'), url('../fonts/GT-Alpina-Regular-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

